#page-transition {
  //overflow: hidden;
  //
  // z-index: 9999;
  // width: 100vw;
  //display: flex;
  //height: 100%;
  //justify-content: center;
  //align-items: center;
}

.body-page-transition {
  //overflow: hidden;
  //position: fixed;
  width: 100%;
  // max-height: 100vh;
 
  #page-body {
    // visibility: hidden;
    // display: none;

    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
  }
  #page-transition-container {
    //  overflow: hidden;
    min-width: 100%;
    background-color: white;
    min-height: 80vh;
    position: relative;
    z-index:1000;
  }
  //#1A4B55;
}
/*
.body-page-transition-home-header {
    header {
    background-color: #0C5362 !important;
    height: 60px; //should be 60px for full site
    padding: 0;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 12px -5px rgba(33, 33, 33, 0.46);
    transition: all 150ms ease-in-out;
  }
}
*/


//#page-transition > div {
//  &:after {
//content: '';
// background-image: url("../img/bg7.jpg");
//  background-size: cover;
//  position: fixed;
//  z-index: 9998;
//  width: 100%;
//  height: 100%;
//  top: 0;
//  left: 0;

//}
//   &:before {
//   top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
// content: '';
//  display: flex;
//  z-index: 9999;
//  position: absolute;
//  background: rgba(255, 255, 255, 0);
//   min-height: 100vh;

//    }
//}
