.addLoadingDots:before {
  animation: dots 1s linear infinite;
  content: '';
}
.addLoadingDots {
  position: absolute;
}
@keyframes dots {
  0%,
  20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  90%,
  100% {
    content: '';
  }
}
